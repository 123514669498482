const ROUTES = {
  ARTICLE: '/article/[slug]',
  BLOG: '/blog',
  BRANDS: '/brands',
  BRAND_PAGE: '/brands/[slug]',
  CATEGORY: '/category',
  CATEGORY_MENU_PAGE: '/category/[menuSlug]',
  CATEGORY_GROUP_PAGE: '/category/[menuSlug]/[groupSlug]',
  CHECKOUT: '/checkout',
  CITY_PAGE: '/weed-delivery/[slug]',
  WEED_DELIVERY: '/weed-delivery',
  GROUP: '/groups', // used for getCurrentPath as we want to use pathname
  GROUPS: '/groups/[groupSlug]',
  LOGIN: '/login',
  MENU: '/menu',
  ORDER_STATUS: '/orders/[id]',
  PACKER_APP_LINKS: '/partner-apps/pack',
  PRESS: '/press',
  PRESS_PAGE: '/press/[slug]',
  PROFILE: '/profile',
  RESET: '/reset/[id]',
  RESET_PASSWORD: '/reset-password',
  ROOT: '/',
  SIGNUP: '/signup',
  VERIFY: '/verify'
} as const
export type RouteNames = keyof typeof ROUTES
export type Routes = typeof ROUTES[RouteNames]

export const BASE_ROUTES: Routes[] = [
  ROUTES.ROOT,
  ROUTES.ARTICLE,
  ROUTES.BLOG,
  ROUTES.BRANDS,
  ROUTES.BRAND_PAGE,
  ROUTES.WEED_DELIVERY,
  ROUTES.CITY_PAGE,
  ROUTES.MENU,
  ROUTES.GROUP,
  ROUTES.GROUPS,
  ROUTES.CATEGORY_MENU_PAGE,
  ROUTES.CATEGORY_GROUP_PAGE
]
export const SHOPPING_ROUTES: Routes[] = [
  ROUTES.MENU,
  ROUTES.GROUPS,
  ROUTES.CATEGORY_MENU_PAGE,
  ROUTES.CATEGORY_GROUP_PAGE,
  ROUTES.BRAND_PAGE
]
export const SHOPPING_PATHS: Routes[] = [ROUTES.MENU, ROUTES.GROUP, ROUTES.CATEGORY, ROUTES.BRANDS]
export const DYNAMIC_SHOPPING_ROUTES: Routes[] = [
  ROUTES.GROUPS,
  ROUTES.ARTICLE,
  ROUTES.CITY_PAGE,
  ROUTES.CATEGORY_MENU_PAGE,
  ROUTES.CATEGORY_GROUP_PAGE,
  ROUTES.BRAND_PAGE
]

export default ROUTES
